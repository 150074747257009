import React from "react"
import Layout from "../components/Layout"
import Pricelist from "../components/Pricelist"
import SEO from '../components/seo'

export default function PricingPage() {
    return (
        <Layout>
          <SEO 
            title={'LiaLash Beauty Price List'} 
          />
          <h1>Price List</h1>
          <Pricelist />
        </Layout>
    )
}
