import React from "react"
import pageHeader from "../../images/new/page-header.jpg"
import image1 from "../../images/new/pricelist-image1.jpg"
import image2 from "../../images/new/pricelist-image2.jpg"

const Pricelist = () => {
    return (
        <div className="container pricelist">
        <div className="page__header" style={{ backgroundImage: `url(${pageHeader})` }}/>
        <h2 id="eyelashes">Lashes</h2>
        <div>
        <h2 className="space-above">Glam Volume Full Set 5/6D</h2>
          {/* <p className="description">This set is tailored using 5 to 8 very light lashes per each natural lash to provide the ultimate
          glam look.</p> */}
          <p>Full Set $170 (2 Hours) </p>
          <p>2 Week Infill $95 (1 hour)</p>
          <p>3 Week Infill $110 (1 hour)</p>
        </div>
        <img src={image1} className="content__image hide-mobile" alt="LiaLash Beauty Work" />
        <div>
          <h2 className="space-above">Mega Volume Full Set 8/12D</h2>
          {/* <p className="description">Level Up with a more dramatic look. Using ultra fine lashes, volume fans are made of at least 8 lashes 
          to up to 15 (8-15D).</p> */}
          <p>Mega Volume (2 Hours @ $190)</p>
          <p>2 Week Infill $110 (1 hour)</p>
          <p>3 Week Infill $130 (1.5 hour)</p>
        </div>
        <div>
          <h2 className="space-above">Natural Volume Full Set 3/4D</h2>
          {/* <p className="description">An Effortless look to enhance your eyes. Customised to your preference, this full set is crafted with 
          2 to 5 very light lashes (2-5D). No more mascara is needed for this everyday look.</p> */}
          <p>Full Set $150 (1.5 Hours)</p>
          <p>2 Week Infill $95 (1 hour)</p>
          <p>3 Week Infill $110 (1 hour)</p>
        </div>
        <p className="smaller">Infills recommend 3 to 4 weeks. Please note infills after 4 weeks will be considered as a full set. 
Price will vary if there is more than 50% lashes to fill.</p>
        <img src={image2} className="content__image hide-mobile" alt="LiaLash Beauty Work" />
        <h2 id="lash-lift">Lifts</h2>
        <div>
          <p>Keratin Lash Lift &amp; Tint - $100</p>
          <p>Brow Lamination Lift &amp; Tint $90</p>
        </div>
        <h2 id="brows">Beauty Services</h2>
        <div>
          <p>Airbrush Spray Tan $50</p>
          <p>HD Brow Tint $15</p>
          <p>Brow Shape &amp; Tidy $15</p>
          <p>Brow Makeover (Brow Shape &amp; Tidy + HD Brow Tint) $30</p>
          <p>Lash Extension Removal $15</p>
          <p>Maintenance Waxing (includes lips, nose, chin) from $10</p>
        </div>
      </div>
    )
}

export default Pricelist;